import React, {Fragment, useContext, useEffect, useMemo, useState} from "react";
import {EnvContext} from "../../App";
import CaseEntryService from "../../services/CaseEntryService";
import {useLocation, useNavigate} from "react-router-dom";
import {useForm} from "react-hook-form";
import Activity from "./Activity";
import LoadingSpinner from "../LoadingSpinner";
import AlertWithDescription from "../alerts/AlertWithDescription";

const DEFAULT_CASE_MANAGER_VALUE = "";
const DEFAULT_CASE_NUMBER_VALUE = "";
const CASE_NOT_LISTED_ID = -100;
export default function TimeEntryForm() {
    const envProvider = useContext(EnvContext);
    const navigate = useNavigate();
    const location = useLocation();
    const [matters, setMatters] = useState([]);
    const [manualCaseNumberRequired, setManualCaseNumberRequired] = useState(false);
    const [disabledCaseManagerValue, setDisabledCaseManagerValue] = useState();
    const [formValues, setFormValues] = useState({
        activity: "",
        index: -1
    });
    const caseEntryService = useMemo(() => {
        return new CaseEntryService(envProvider)
    }, [envProvider]);
    const caseManagers = useMemo(() => {
        return envProvider.caseManagers;
    }, [envProvider]);
    const activities = useMemo(() => {
        return envProvider.activities;
    }, [envProvider]);

    useEffect(() => {
        caseEntryService.getMatters((matters) => {
            setMatters(matters)
        }, (error) => {
            alert("An error occurred loading cases");
        }, () => setLoading(false))
    }, [caseEntryService])

    const {
        register, formState: {errors}, handleSubmit, setValue
    } = useForm({
        defaultValues: {
            minutes: 0,
            hours: 0,
            activity: 0,
            quantity1: 0,
            quantity2: 0,
            date: "",
            caseManager: DEFAULT_CASE_MANAGER_VALUE,
            caseNumberId: DEFAULT_CASE_NUMBER_VALUE
        }
    });

    const [loading, setLoading] = useState(true);
    const [errorMessage, setErrorMessage] = useState("");

    //Get activity labels
    let activityLabels = [];
    activities.forEach(activity => {
        activityLabels.push(activity.label);
    });

    useEffect(() => {
        let caseManager = location.state?.caseManager;
        let caseNumberId = location.state?.caseNumberId;
        console.log("Updating options from location args: ", caseManager, caseNumberId)
        if (caseNumberId && caseManager) {
            const matter = matters.find(m => m.id === +caseNumberId);
            let caseManagerEmail = null;
            if (matter && matter.responsibleAttorney && matter.responsibleAttorney.email) {
                caseManagerEmail = matter.responsibleAttorney.email;
            } else {
                caseManagerEmail = DEFAULT_CASE_MANAGER_VALUE;
            }
            console.log("Setting Case Number on Load", caseNumberId, matter)
            setValue('caseNumberId', caseNumberId);
            setManualCaseNumberRequired(+caseNumberId === CASE_NOT_LISTED_ID);
            if (caseManagerEmail !== DEFAULT_CASE_MANAGER_VALUE) {
                setDisabledCaseManagerValue(caseManagerEmail)
                setValue('caseManager', caseManagerEmail);
            } else {
                setValue('caseManager', caseManager);
            }
        }
    }, [location, setFormValues, setValue, matters]);

    const onCaseNumberChange = (event) => {
        const id = +event.target.value;
        setManualCaseNumberRequired(id === CASE_NOT_LISTED_ID);
        const matter = matters.find(m => m.id === id);
        let caseManagerEmail = null;
        if (matter && matter.responsibleAttorney && matter.responsibleAttorney.email) {
            caseManagerEmail = matter.responsibleAttorney.email;
        } else {
            caseManagerEmail = DEFAULT_CASE_MANAGER_VALUE;
        }
        if (caseManagerEmail !== DEFAULT_CASE_MANAGER_VALUE) {
            setDisabledCaseManagerValue(caseManagerEmail)
            setValue('caseManager', caseManagerEmail, {shouldValidate: true});
        } else {
            setDisabledCaseManagerValue(undefined)
            setValue('caseManager', caseManagerEmail, {shouldDirty: true});
        }
    }

    const getDisabledCaseManagerDisplayValue = (email) => {
        return caseManagers.find(cm => cm.value === email)?.label || email;
    }

    const onActivityChanged = e => {
        const activity = activities.find(x => x.label === e.target.value);
        const ndx = activities.indexOf(activity);
        setFormValues(prevState => {
            return {...prevState, activity: e.target.value, index: ndx}
        });
        setValue("activity", activity.value);
        if (!activity.timeRequired) {
            setValue('minutes', 0)
            setValue('hours', 0)
        }
        if (!activity.dateRequired) {
            setValue('date', '')
        }
        if (!activity.quantity1Required) {
            setValue('quantity1', 0)
        }
        if (!activity.quantity2Required) {
            setValue('quantity2', 0)
        }
    }

    //Form submit function
    const submitEntry = data => {
        if (loading) {
            // Preventing Duplicate Submit.
            return;
        }
        setLoading(true);
        data.time = data.minutes + (data.hours * 60);
        // When the field is disabled, it's not submitted in the payload
        caseEntryService.post(data, () => {
            navigate('/success', {
                replace: true,
                state: {
                    caseManager: data.caseManager,
                    caseNumberId: data.caseNumberId
                }
            });
        }, (response) => {
            let errorMessage = 'Something went wrong. Please try again.';
            if (response) {
                errorMessage += ' (' + response + ')';
            }
            setErrorMessage(errorMessage);
            setLoading(false);
        })
    }

    console.log("Errors", errors);

    function isCaseManagerDisabled() {
        return disabledCaseManagerValue != null;
    }

    return (
        <div className="flex w-full h-fit flex-col items-center">
            {loading && <LoadingSpinner showOverlay={true}
                                        classNames="justify-center items-center h-72 pt-28 absolute align-middle"/>}
            <form onSubmit={handleSubmit(submitEntry)}
                  className="max-w-screen-lg w-full space-y-8 sm:space-y-5 border-primary p-8">
                {errorMessage && <AlertWithDescription message={errorMessage} type={"error"}/>}
                <div>
                    <h2 className="mt-6 text-center text-3xl tracking-tight font-bold text-gray-900 ">
                        Time Entry
                    </h2>
                </div>
                <div className="space-y-6 sm:space-y-5">
                    <div className="grid grid-cols-8 gap-4">
                        <div
                            className={manualCaseNumberRequired ? "col-span-4 sm:col-span-2" : "col-span-8 sm:col-span-4"}>
                            <label htmlFor="caseNumberId" className="block text-sm font-medium text-gray-700">
                                Case Number
                            </label>
                            <select
                                {...register("caseNumberId", {
                                    required: {
                                        value: true,
                                        message: "Case Number is Required."
                                    }
                                })}
                                id="caseNumberId"
                                name="caseNumberId"
                                className="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-primary-500 focus:border-primary-500 focus:z-10 sm:text-sm mt-2"
                                onChange={onCaseNumberChange}
                                required
                            >
                                <option value={DEFAULT_CASE_NUMBER_VALUE} disabled>Select Case</option>
                                {matters.map((matter) => {
                                    return <option key={matter.id} value={matter.id}>{matter.displayNumber}</option>
                                })}
                            </select>
                            {errors.caseNumberId && <p className="mt-2 text-sm text-red-600" id="case-number-error">
                                {errors.caseNumberId.message}
                            </p>}
                        </div>
                        {manualCaseNumberRequired &&
                            <div className="col-span-4 sm:col-span-2">
                                <label htmlFor="caseNumberManualEntry"
                                       className="block text-sm font-medium text-gray-700">
                                    Manual Case Number
                                </label>
                                <input {...register("caseNumberManualEntry", {
                                    required: {
                                        value: true,
                                        message: "Manual Case Number is Required."
                                    }
                                })}

                                       type="text"
                                       id="caseNumberManualEntry"
                                       name="caseNumberManualEntry"
                                       required
                                       className="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-primary-500 focus:border-primary-500 focus:z-10 sm:text-sm mt-2"/>
                                {errors.caseNumberManualEntry &&
                                    <p className="mt-2 text-sm text-red-600" id="case-number-manual-entry-error">
                                        {errors.caseNumberManualEntry.message}
                                    </p>}
                            </div>
                        }
                        <div className="col-span-8 sm:col-span-4">
                            <div>
                                <label htmlFor="caseManager" className="block text-sm font-medium text-gray-700">
                                    Case Manager
                                </label>
                                {isCaseManagerDisabled() && <div>
                                    <input type="hidden"
                                           id="caseManager"
                                           name="caseManager"
                                           {...register("caseManager", {
                                               required: {
                                                   value: true,
                                                   message: "Case Manager is Required."
                                               }
                                           })}/>
                                    <input type="text" disabled="disabled"
                                           value={getDisabledCaseManagerDisplayValue(disabledCaseManagerValue)}
                                           className="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-primary-500 focus:border-primary-500 focus:z-10 sm:text-sm mt-2"/>
                                </div>}
                                {!isCaseManagerDisabled() && <select
                                    {...register("caseManager", {
                                        required: {
                                            value: true,
                                            message: "Case Manager is Required."
                                        }
                                    })}
                                    id="caseManager"
                                    name="caseManager"
                                    className="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-primary-500 focus:border-primary-500 focus:z-10 sm:text-sm mt-2"
                                    required
                                >
                                    <option value={DEFAULT_CASE_MANAGER_VALUE} disabled>Select Case Manager</option>
                                    {caseManagers.map((caseManager) => {
                                        return <option key={caseManager.value}
                                                       value={caseManager.value}>{caseManager.label}</option>
                                    })}
                                </select>}
                            </div>
                            {errors.caseManager && <p className="mt-2 text-sm text-red-600" id="case-manager-error">
                                {errors.caseManager.message}
                            </p>}
                        </div>
                    </div>
                </div>
                <div className="grid grid-cols-6 gap-4">
                    <div className="col-span-full">
                        <label htmlFor="activity" className="block text-sm font-medium text-gray-700">
                            Activity
                        </label>
                        <select
                            id="activity"
                            name="activity"
                            className="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-primary-500 focus:border-primary-500 focus:z-10 sm:text-sm mt-2"
                            value={formValues.activity}
                            onChange={e => onActivityChanged(e)}
                            required
                        >
                            <option value="" disabled>Select Activity</option>
                            {activityLabels.map((value) => {
                                return <option key={value} value={value}>{value}</option>
                            })}
                        </select>
                    </div>
                </div>
                {(formValues.index >= 0) ?
                    <Activity option={activities[formValues.index]} setFormValue={setValue}/> :
                    <span></span>}
                <div className="grid grid-cols-6 gap-4">
                    <div className="col-span-full">
                        <label htmlFor="comments" className="block text-sm font-medium text-gray-700">
                            Comments (Hours by Date Work Performed (Initial Review and Additions), Volumes Reviewed, Details of Research Performed)
                        </label>
                        <textarea
                            {...register("comments")}
                            maxLength={144}
                            rows={4}
                            placeholder="Additional Comments"
                            name="comments"
                            id="comments"
                            className="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-primary-500 focus:border-primary-500 focus:z-10 sm:text-sm mt-2"
                        />
                    </div>
                </div>
                <div className="pt-5 justify-center flex">
                    <div className="flex justify-end">
                        <button
                            type="submit"
                            className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                        >
                            Submit Entry
                        </button>
                    </div>
                </div>
            </form>
        </div>
    )
}
